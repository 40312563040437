export const socialLinks = [
	
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/habbarmohamed',
	},
	{
		id: 3,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://instagram.com/iammohamedhabbar',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/mohamed-habbar-22bb1215a',
	}
];
