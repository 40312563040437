<script>
import feather from 'feather-icons';

export default {
  name: 'Home',
  data: () => {
    return {
      theme: '',
    };
  },
  created() {
    this.theme = localStorage.getItem('theme') || 'light';
  },
  mounted() {
    feather.replace();
    this.theme = localStorage.getItem('theme') || 'light';
  },
  updated() {
    feather.replace();
  },
  methods: {},
};
</script>

<template>
  <section class="flex flex-col sm:flex-row items-center sm:items-start justify-between mt-12 sm:mt-16 px-6 sm:px-12 lg:px-24 py-12">
    
    <div class="w-full sm:w-1/2 text-center sm:text-left space-y-6">
      <h1 class="font-semibold text-4xl sm:text-5xl lg:text-6xl text-gray-900 dark:text-white uppercase tracking-tight">
        Hi, I’m Habbar Mohamed
      </h1>
      <p class="text-lg sm:text-xl text-gray-700 dark:text-gray-300 leading-relaxed">
        A passionate Web Developer <span class="text-sky-500">Laravel, VueJS</span>
      </p>
      <div class="flex justify-center sm:justify-start">
        <a
          download
          href="#"
          class="flex justify-center items-center w-40 sm:w-48 mt-6 py-3 px-6 text-lg font-medium border border-sky-500 bg-sky-50 dark:bg-sky-600 text-gray-600 dark:text-white rounded-lg shadow-xl transition-all duration-300 hover:bg-sky-600 hover:text-white focus:ring-2 focus:ring-sky-500 transform hover:scale-105"
          aria-label="Download Resume"
        >
          <i data-feather="arrow-down-circle" class="w-6 h-6 sm:w-7 sm:h-7 mr-2"></i>
          Download CV
        </a>
      </div>
    </div>

    
    <div class="w-full sm:w-1/2 text-center sm:text-right mt-8 sm:mt-0">
      <img
        v-if="theme === 'light'"
        src="@/assets/images/developer.svg"
        alt="Developer"
        class="w-full max-w-md mx-auto transform transition-all duration-500 hover:scale-105"
      />
      <img
        v-else
        src="@/assets/images/developer-dark.svg"
        alt="Developer"
        class="w-full max-w-md mx-auto transform transition-all duration-500 hover:scale-105"
      />
    </div>
  </section>
</template>

<style scoped>
/* Ensure smooth transition on images */
img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

/* Make text and elements more responsive */
@media (max-width: 640px) {
  h1 {
    font-size: 2.25rem;
  }
  p {
    font-size: 1rem;
  }
}
</style>
