<script>
import feather from 'feather-icons';
import FooterCopyright from './FooterCopyright.vue';
import { socialLinks } from '../../data/socialLinks';

export default {
  components: { FooterCopyright },
  data() {
    return {
      socials: socialLinks,
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="container mx-auto">
    <div class="pt-20 sm:pt-30 pb-8 mt-20 border-t-2 border-primary-light dark:border-secondary-dark">
      
      <div class="flex flex-col justify-center items-center mb-12 sm:mb-20">
        <p class="font-semibold text-3xl sm:text-4xl text-center text-primary-dark dark:text-primary-light mb-5">
          Follow me
        </p>
        <ul class="flex gap-6 sm:gap-10">
          <a
            v-for="social in socials"
            :key="social.id"
            :href="social.url"
            target="__blank"
            class="text-gray-500 hover:text-sky-500 dark:hover:text-sky-400 cursor-pointer rounded-full bg-gray-50 dark:bg-ternary-dark hover:bg-gray-100 shadow-md p-3 sm:p-4 duration-300"
            aria-label="Follow on {{ social.name }}"
          >
            <i :data-feather="social.icon" class="w-6 sm:w-8 h-6 sm:h-8"></i>
          </a>
        </ul>
      </div>

      
      <FooterCopyright />
    </div>
  </div>
</template>

<style scoped>
/* Optional: Enhance responsiveness for mobile screens */
@media (max-width: 640px) {
  .text-3xl {
    font-size: 2xl;
  }
  .text-4xl {
    font-size: 3xl;
  }
}
</style>
