// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		slug: 'stock-management',
		title: 'Stock Management',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'Backend',
		img: require('@/assets/images/stock.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Laravel',
			'AJAX',
			'JQuery',
			'Bootstrap',
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "For Myself",
		companyServices: "For Myself",
		companyWebsite: "For Myself",
		companyPhone: "For Myself",
		projectDescription: "Managing inventory involves balancing supply and demand, while maintaining accurate product information across various sales channels. Optimizing sales and purchasing processes is crucial for enhancing customer satisfaction and minimizing operational costs. Secure payment processing ensures reliability and customer trust during transactions. Operational challenges include navigating logistics complexities, synchronizing data effectively, and managing financial aspects to ensure streamlined operations and sustainable profitability in retail environments",
		firstImg: require('@/assets/images/stock.png'),
		secondImg: require('@/assets/images/stock.png'),
		thirdImg: require('@/assets/images/stock.png'),
	},
	{
		id: 2,
		slug: 'presence-management',
		title: 'Presence Management',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'Backend',
		description: 'Our Employee Presence and Management System uses badge technology to efficiently track employee attendance and manage employee information. Employees check in and out using personalized badges, ensuring accurate and automated attendance records. The system provides real-time monitoring of employee movements, detailed attendance reports, and centralized employee data management. It also includes role and permission management, enabling secure and controlled access to various workplace areas and resources.',
		img: require('@/assets/images/presence.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'TailwindCSS',
			'AdobeXD',
		],
		objective: "Implement a system that automates the recording of employee attendance through clock-in/clock-out functionalities, reducing manual entry and minimizing errors.",
		companyName: "Umnia Bank",
		companyServices: "Employee attendance management web application",
		companyWebsite: "https://www.umniabank.ma/",
		companyPhone: "....",
		projectDescription: "The Employee Attendance Management Web Application is a robust and user-friendly system designed to streamline and automate the process of tracking employee attendance. This web-based solution caters to businesses of all sizes, offering a comprehensive platform to manage attendance records, monitor employee working hours, and generate insightful reports. By leveraging modern web technologies, this application aims to enhance efficiency, reduce administrative workload, and ensure accurate attendance management.",
		firstImg: require('@/assets/images/presence.png'),
		secondImg: require('@/assets/images/presence.png'),
		thirdImg: require('@/assets/images/presence.png'),


	},
	{
		id: 4,
		slug: 'dental-management',
		title: 'Dental Practice Management',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'VueJS',
		img: require('@/assets/images/dent.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Vue.js',
			'Bootstrap',
			'Figma',
			'API',
		],
		objective: "Implement a system for dentists to easily insert and manage dental procedures (actes) performed on patients, including details such as procedure type, date, and patient information.",
		companyName: "Confidential (Commercial Client)",
		companyServices: "",
		companyWebsite: "Unknown",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/dent.png'),
		secondImg: require('@/assets/images/dent.png'),
		thirdImg: require('@/assets/images/dent.png'),

	},
	{
		id: 5,
		slug: 'event-app',
		title: 'Event Management Project Web App',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'Laravel',
		img: require('@/assets/images/rapport.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Laravel'
		],
		objective: "Streamline the planning, organization, and execution of events through automated workflows and centralized management tools.",
		companyName: "Youtech",
		companyServices: "An event management web application also handles reporting, incidents, and complaints effectively.",
		companyWebsite: "https://youtech.ma/",
		companyPhone: "",
		projectDescription: "An event management web application serves as a comprehensive solution for organizing and managing events while also efficiently handling reporting, incidents, and complaints. Beyond facilitating seamless event planning and execution, this robust platform integrates tools for generating detailed reports on attendance, financial metrics, and participant feedback. It enables event organizers to monitor and respond promptly to incidents and complaints, ensuring smooth operations and attendee satisfaction. By centralizing communication channels and providing real-time incident tracking and resolution capabilities, the application enhances transparency and accountability throughout the event lifecycle. This holistic approach not only optimizes event logistics such as budgeting, vendor management, and scheduling but also strengthens event safety protocols and risk management practices. Ultimately, the application empowers organizers to deliver exceptional event experiences while maintaining a proactive approach to handling challenges and feedback.",
		firstImg: require('@/assets/images/rapport.png'),
		secondImg: require('@/assets/images/rapport.png'),
		thirdImg: require('@/assets/images/rapport.png'),

	},
	{
		id: 6,
		slug: 'school-management',
		title: 'School Management',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'Laravel',
		img: require('@/assets/images/ccf.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'AJAX',
			'JQuery',
			'Bootstrap',
			'AdobeXD',
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "CCF | EFMS | EFMC",
		companyServices: "",
		companyWebsite: "https://www.efms.ma/",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/ccf.png'),
		secondImg: require('@/assets/images/ccf.png'),
		thirdImg: require('@/assets/images/ccf.png'),

	},
	{
		id: 7,
		slug: 'commercial-management',
		title: 'Commercial Management',
		category: 'Web Application',
		// date: 'Jul 26, 2021',
		tag: 'Laravel',
		img: require('@/assets/images/wex.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Laravel',
			'VueJS',
			'Bootstrap'
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "WEBEX AG",
		companyServices: "",
		companyWebsite: "https://webexag.com/",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/wex.png'),
		secondImg: require('@/assets/images/wex.png'),
		thirdImg: require('@/assets/images/wex.png'),

	},
	{
		id: 8,
		slug: 'company-site',
		title: 'Company Website',
		category: 'Website',
		// date: 'Jul 26, 2021',
		tag: 'Wordpress',
		img: require('@/assets/images/webex.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Wordpress'
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "WEBEX AG",
		companyServices: "",
		companyWebsite: "https://webexag.com/",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/webex.png'),
		secondImg: require('@/assets/images/webex.png'),
		thirdImg: require('@/assets/images/webex.png'),
	},
	{
		id: 9,
		slug: 'syndic-site',
		title: 'Syndic Management',
		category: 'Website',
		// date: 'Jul 26, 2023',
		tag: 'Web Application',
		img: require('@/assets/images/raha.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Laravel',
			'AJAX',
			'JQuery',
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "RAHA Syndic",
		companyServices: "",
		companyWebsite: "https://www.raha.ma/",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/raha.png'),
		secondImg: require('@/assets/images/raha.png'),
		thirdImg: require('@/assets/images/raha.png'),

	},
	{
		id: 10,
		slug: 'commerce-site',
		title: 'Store',
		category: 'Website',
		// date: 'Jul 26, 2023',
		tag: 'Website & Web Application',
		img: require('@/assets/images/arex.png'),
		techs: [
			'HTML',
			'CSS',
			'JavaScript',
			'Laravel',
			'AJAX',
			'JQuery',
		],
		objective: "Manage inventory to meet demand, showcase products effectively, and streamline sales, purchases, and payments for efficient store operations.",
		companyName: "AREX",
		companyServices: "",
		companyWebsite: "https://www.arex.ma/",
		companyPhone: "",
		projectDescription: "",
		firstImg: require('@/assets/images/arex.png'),
		secondImg: require('@/assets/images/arex.png'),
		thirdImg: require('@/assets/images/arex.png'),

	},
];

export default projects;
