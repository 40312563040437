<script>
import feather from 'feather-icons';
import ProjectHeader from '../components/projects/ProjectHeader.vue';
import ProjectGallery from '../components/projects/ProjectGallery.vue';
import ProjectInfo from '../components/projects/ProjectInfo.vue';
// import ProjectRelatedProjects from '../components/projects/ProjectRelatedProjects.vue';
import projects from '../data/projects';


export default {
	name: 'DetailsProject',
	components: {
		ProjectHeader,
		ProjectGallery,
		ProjectInfo,
		// ProjectRelatedProjects,
	},
	data: () => {
		return {
			projectData: {
				projectTitle: null,
				projectCategory: null,
				projectTag: null,
				// projectDate: null,
			},
			// singleProjectHeader: {
			// 	projectTitle: 'Project Management UI',
			// 	projectDate: 'Jul 26, 2021',
			// 	projectTag: 'UI / Frontend',
			// },
			projectImages: [
				{
					id: 1,
					title: '',
					img: '',
				},
				{
					id: 2,
					title: '',
					img: '',
				},
				{
					id: 3,
					title: '',
					img: '',
				},
			],
			projectInfo: {
				clientHeading: 'About Client',
				companyInfos: [
					{
						id: 1,
						title: 'Name',
						details: '',
					},
					{
						id: 2,
						title: 'Services',
						details: '',
					},
					{
						id: 3,
						title: 'Website',
						details: '',
					},
					{
						id: 4,
						title: 'Phone',
						details: '',
					},
				],
				objectivesHeading: 'Objective',
				objectivesDetails:
					'Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, natus! Quibusdam enim quod in esse, mollitia molestias incidunt quas ipsa accusamus veniam.',
				technologies: [
					{
						title: 'Tools & Technologies',
						techs: [],
					},
				],
				projectDetailsHeading: 'Challenge',
				projectDescription: '',
				socialSharingsHeading: 'Share This',
				socialSharings: [
					{
						id: 2,
						name: 'Instagram',
						icon: 'instagram',
						url: 'https://instagram.com/iammohamedhabbar',
					},
					{
						id: 3,
						name: 'Facebook',
						icon: 'facebook',
						url: 'https://facebook.com/sim.https',
					},
					{
						id: 4,
						name: 'LinkedIn',
						icon: 'linkedin',
						url: 'https://linkedin.com/habbarmohamed',
					},
				],
			},
			// relatedProject: {
			// 	relatedProjectsHeading: 'Related Projects',
			// 	relatedProjects: [
			// 		{
			// 			id: 1,
			// 			title: 'Mobile UI',
			// 			img: require('@/assets/images/mobile-project-1.jpg'),
			// 		},
			// 		{
			// 			id: 2,
			// 			title: 'Web Application',
			// 			img: require('@/assets/images/web-project-1.jpg'),
			// 		},
			// 		{
			// 			id: 3,
			// 			title: 'UI Design',
			// 			img: require('@/assets/images/ui-project-2.jpg'),
			// 		},
			// 		{
			// 			id: 4,
			// 			title: 'Kabul Mobile App UI',
			// 			img: require('@/assets/images/mobile-project-2.jpg'),
			// 		},
			// 	],
			// },
			projects
		};
	},
	mounted() {
		feather.replace();
		let slug = this.$route.params.slug;
		this.searchProject(slug);
	},
	updated() {
		feather.replace();
	},
	methods: {
		searchProject(enteredString){
			const result = projects.filter(data=> data?.slug?.includes(enteredString))[0];
			this.projectData.projectTitle = result.title;
			this.projectData.projectCategory = result.category;
			// this.projectData.projectDate = result.date;
			this.projectData.projectTag = result.tag;
			this.projectInfo.projectDescription = result.projectDescription;
			this.projectInfo.technologies[0].techs.push(...result.techs);
			this.projectInfo.objectivesDetails = result.objective;
			this.projectInfo.companyInfos.forEach(info => {
				switch (info.title) {
					case 'Name':
						info.details = result.companyName;
						break;
					case 'Services':
						info.details = result.companyServices;
						break;
					case 'Website':
						info.details = result.companyWebsite;
						break;
					case 'Phone':
						info.details = result.companyPhone;
						break;
					default:
						break;
				}
			});
			this.projectImages.forEach(info => {
				switch (info.id) {
					case 1:
						info.img = result.firstImg;
						break;
					case 2:
						info.img = result.secondImg;
						break;
					case 3:
						info.img = result.thirdImg;
						break;
					default:
						break;
				}
			});

		}

	},
};
</script>

<template>
	<div class="container mx-auto mt-10 sm:mt-20">
        
		
		<ProjectHeader :projectData="projectData" />
        
		
		<ProjectGallery :projectImages="projectImages" />

		
		<ProjectInfo :projectInfo="projectInfo" />

		
		
	</div>
</template>

<style scoped></style>
