<script>
import feather from 'feather-icons';
import ProjectsFilter from './ProjectsFilter.vue';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
  components: { ProjectSingle, ProjectsFilter },
  data: () => {
    return {
      projects,
      projectsHeading: 'Our Creative Projects',
      selectedCategory: '',
      searchProject: '',
    };
  },
  computed: {
    filteredProjects() {
      if (this.selectedCategory) {
        return this.filterProjectsByCategory();
      } else if (this.searchProject) {
        return this.filterProjectsBySearch();
      }
      return this.projects;
    },
  },
  methods: {
    filterProjectsByCategory() {
      return this.projects.filter((item) => {
        let category =
          item.category.charAt(0).toUpperCase() + item.category.slice(1);
        return category.includes(this.selectedCategory);
      });
    },
    filterProjectsBySearch() {
      let project = new RegExp(this.searchProject, 'i');
      return this.projects.filter((el) => el.title.match(project));
    },
  },
  mounted() {
    feather.replace();
  },
};
</script>

<template>
  <section class="py-16 bg-gray-50 dark:bg-gray-900">
    <div class="text-center mb-10">
      <h2 class="text-4xl font-semibold text-gray-800 dark:text-white">{{ projectsHeading }}</h2>
    </div>

    <div class="max-w-7xl mx-auto px-4">
      <h3 class="text-lg sm:text-xl font-medium text-gray-600 dark:text-gray-300 mb-4 text-center">
        Find projects by title or filter by category
      </h3>
      
      <div class="flex justify-between items-center mb-6 gap-4 sm:gap-8">
        <div class="flex-1 relative">
          <input
            v-model="searchProject"
            class="w-full p-3 pl-10 pr-4 rounded-lg bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-white border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="search"
            placeholder="Search Projects"
            aria-label="Search Projects"
          />
          <i data-feather="search" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400"></i>
        </div>

        <ProjectsFilter @filter="selectedCategory = $event" />
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <ProjectSingle
          v-for="project in filteredProjects"
          :key="project.id"
          :project="project"
          class="transform transition-all hover:scale-105 hover:shadow-xl hover:opacity-90"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Optional: Add custom styling or transitions for cards */
.project-card {
  transition: all 0.3s ease-in-out;
}

.project-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}
</style>
