<script>
export default {
	props: ['singleProjectHeader', 'projectData'],
};
</script>

<template>
	<div>
		
		<p
			class="font-general-medium text-left text-3xl sm:text-4xl font-bold text-primary-dark dark:text-primary-light mt-14 sm:mt-20 mb-7"
		>
			{{ projectData.projectTitle }} 
		</p>
		<div class="flex">
			<!-- <div class="flex items-center mr-10">
				<i
					data-feather="clock"
					class="w-4 h-4 text-ternary-dark dark:text-ternary-light"
				></i>
				<span
					class="font-general-medium ml-2 leading-none text-primary-dark dark:text-primary-light"
					>{{ projectData.projectDate }}</span
				>
			</div> -->
			<div class="flex items-center">
				<i
					data-feather="tag"
					class="w-4 h-4 text-ternary-dark dark:text-ternary-light"
				></i>
				<span
					class="font-general-medium ml-2 leading-none text-primary-dark dark:text-primary-light"
					>{{ projectData.projectTag }}</span
				>
			</div>
		</div>
	</div>
</template>
